

export const navLinks = [
  {
    id: "about",
    title: "About",
    idStat:true,
  },
  {
    id: "projects",
    title: "Projects",
    idStat:true,
  },
  {
    id: "contact",
    title: "Contact",
    idStat:true,
  },
  // {
  //   id: "blog",
  //   title: "Blogs",
  //   idStat:false,
  // },
];

const services = [
  {
    title: "Web Developer",
    icon: 'https://res.cloudinary.com/doe77lyoi/image/upload/v1725259874/hn49eibg3srfvg4bycem.png',
  },
  {
    title: "Flutter Developer",
    icon: 'https://res.cloudinary.com/doe77lyoi/image/upload/v1725259874/p1cnwto6q84zrjivqscx.png',
  },
  {
    title: "Backend Developer",
    icon: 'https://res.cloudinary.com/doe77lyoi/image/upload/v1725259873/yjjiiekz9zlevo8qgfeu.png',
  },
  {
    title: "AI/ML Developer",
    icon: 'https://res.cloudinary.com/doe77lyoi/image/upload/v1725259874/m8pvdkczr0lvvvedvi16.png',
  },
];

const technologies = [
  {
    name: "Html",
    icon: 'https://res.cloudinary.com/doe77lyoi/image/upload/v1725259843/pnzomeejfv7hqik3npmh.png',
    hasname: true,
  },
  {
    name: "css",
    icon: 'https://res.cloudinary.com/doe77lyoi/image/upload/v1725259844/sr3acqoznnnz3lyhfc93.png',
    hasname: true,
  },
  {
    name: "JavaScript",
    icon: '/tech/javascript.png',
    hasname: false,
  },
  {
    name: "TypeScript",
    icon: 'https://res.cloudinary.com/doe77lyoi/image/upload/v1725259843/qurnuo4awf51z1xoi0bg.png',
    hasname: false,
  },
  {
    name: "Python",
    icon: 'https://res.cloudinary.com/doe77lyoi/image/upload/v1725259845/xvbzsdwwbsqgdowrwa4b.png',
    hasname: false,
  },
  {
    name: "Dart",
    icon: 'https://res.cloudinary.com/doe77lyoi/image/upload/v1725259844/ryotsajiaksfqk5ft6ve.png',
    hasname: false,
  },
  {
    name: "React JS",
    icon: 'https://res.cloudinary.com/doe77lyoi/image/upload/v1725259846/mjuzuxmmwdyfnd6wp07r.png',
    hasname: false,
  },
  {
    name: "Redux",
    icon: 'https://res.cloudinary.com/doe77lyoi/image/upload/v1725259845/usypk0wrorjyyhxrhho7.png',
    hasname: true,
  },
  {
    name: "Tailwind",
    icon: 'https://res.cloudinary.com/doe77lyoi/image/upload/v1725259846/tftlyh7cym5daxz83mlf.png',
    hasname: true,
  },
  {
    name: "Next.js",
    icon: 'https://res.cloudinary.com/doe77lyoi/image/upload/v1725259845/le7x2ueju5hcxntl2w6m.png',
    hasname: false,
  },
  {
    name: "Three JS",
    icon: 'https://res.cloudinary.com/doe77lyoi/image/upload/v1725259843/ixrjpzixd9gfpatt01bb.svg',
    hasname: true,
  },
  {
    name: "Flutter",
    icon: 'https://res.cloudinary.com/doe77lyoi/image/upload/v1725259844/n9rioev5kpvdyibtpabr.png',
    hasname: false,
  },
  {
    name: "Django",
    hasname: false,
    icon: 'https://res.cloudinary.com/doe77lyoi/image/upload/v1725259844/wht1hrfuiytwjmnnq46m.png',
  },
  {
    name: "Numpy",
    icon: 'https://res.cloudinary.com/doe77lyoi/image/upload/v1725259845/navwuoowvkfizfxm6iby.png',
    hasname: false,
  },
  {
    name: "Pandas",
    icon: 'https://res.cloudinary.com/doe77lyoi/image/upload/v1725259845/ul5ktulxjnqabj3qn8n6.png',
    hasname: false,
  },
  {
    name: "Seabon",
    icon: 'https://res.cloudinary.com/doe77lyoi/image/upload/v1725259846/fqupqvswnpua1avhq1u1.png',
    hasname: false,
  },
  {
    name: "Matplotlip",
    icon: 'https://res.cloudinary.com/doe77lyoi/image/upload/v1725259844/rgil9yx8d2t0t6v3d5bi.png',
    hasname: false,
  },
 
  {
    name: "Sklearn",
    hasname: false,
    icon: 'https://res.cloudinary.com/doe77lyoi/image/upload/v1725259846/qfdvsdxuvsio9d2jtcvn.png',
  },
  {
    name: "tensorflow",
    icon: 'https://res.cloudinary.com/doe77lyoi/image/upload/v1725259843/mniaxlhizjw7vndz4dys.png',
    hasname: true,
  },
];

const experiences = [
  {
    title: "React.js Developer",
    company_name: "Starbucks",
    icon: 'starbucks.png',
    iconBg: "#383E56",
    date: "March 2020 - April 2021",
    points: [
      "Developing and maintaining web applications using React.js and other related technologies.",
      "Collaborating with cross-functional teams including designers, product managers, and other developers to create high-quality products.",
      "Implementing responsive design and ensuring cross-browser compatibility.",
      "Participating in code reviews and providing constructive feedback to other developers.",
    ],
  },
  {
    title: "React Native Developer",
    company_name: "Tesla",
    icon: 'tesla.png',
    iconBg: "#E6DEDD",
    date: "Jan 2021 - Feb 2022",
    points: [
      "Developing and maintaining web applications using React.js and other related technologies.",
      "Collaborating with cross-functional teams including designers, product managers, and other developers to create high-quality products.",
      "Implementing responsive design and ensuring cross-browser compatibility.",
      "Participating in code reviews and providing constructive feedback to other developers.",
    ],
  },
  {
    title: "Web Developer",
    company_name: "Shopify",
    icon: 'shopify.png',
    iconBg: "#383E56",
    date: "Jan 2022 - Jan 2023",
    points: [
      "Developing and maintaining web applications using React.js and other related technologies.",
      "Collaborating with cross-functional teams including designers, product managers, and other developers to create high-quality products.",
      "Implementing responsive design and ensuring cross-browser compatibility.",
      "Participating in code reviews and providing constructive feedback to other developers.",
    ],
  },
  {
    title: "Full stack Developer",
    company_name: "Meta",
    icon: 'meta.png',
    iconBg: "#E6DEDD",
    date: "Jan 2023 - Present",
    points: [
      "Developing and maintaining web applications using React.js and other related technologies.",
      "Collaborating with cross-functional teams including designers, product managers, and other developers to create high-quality products.",
      "Implementing responsive design and ensuring cross-browser compatibility.",
      "Participating in code reviews and providing constructive feedback to other developers.",
    ],
  },
];

const testimonials = [
  {
    testimonial:
      "I thought it was impossible to make a website as beautiful as our product, but Rick proved me wrong.",
    name: "Sara Lee",
    designation: "CFO",
    company: "Acme Co",
    image: "https://randomuser.me/api/portraits/women/4.jpg",
  },
  {
    testimonial:
      "I've never met a web developer who truly cares about their clients' success like Rick does.",
    name: "Chris Brown",
    designation: "COO",
    company: "DEF Corp",
    image: "https://randomuser.me/api/portraits/men/5.jpg",
  },
  {
    testimonial:
      "After Rick optimized our website, our traffic increased by 50%. We can't thank them enough!",
    name: "Lisa Wang",
    designation: "CTO",
    company: "456 Enterprises",
    image: "https://randomuser.me/api/portraits/women/6.jpg",
  },
];

const projects = [
  {
    name: "Portfolio webiste",
    urls:"https://www.basnetraju.com.np/",
    description:
      "Web-based platform that allows users to search, book, and manage car rentals from various providers, providing a convenient and efficient solution for transportation needs.",
    tags: [
      {
        name: "Next.js",
        color: "blue-text-gradient",
      },
      {
        name: "Framer Motion",
        color: "green-text-gradient",
      },
      {
        name: "tailwind",
        color: "pink-text-gradient",
      },
    ],
    // image: 'raju.png',
    image: 'https://res.cloudinary.com/doe77lyoi/image/upload/w_400,h_300,c_fill/v1725259885/f31tr8coyp6ra2zdlsri.png',
    source_code_link: "https://github.com/Nimeshregmi",
  },
  {
    name: "Agency webiste",
    urls:'https://www.bipincreates.com/',
    description:
      "Web application that enables users to search for job openings, view estimated salary ranges for positions, and locate available jobs based on their current location.",
    tags: [
      {
        name: "Tailwind",
        color: "blue-text-gradient",
      },
      {
        name: "Next.js",
        color: "green-text-gradient",
      },
      {
        name: "Framer Motion",
        color: "pink-text-gradient",
      },
    ],
    image: 'https://res.cloudinary.com/doe77lyoi/image/upload/v1725259884/iyenbkns11sctjxugpey.png',
    source_code_link: "https://github.com/Nimeshregmi",
  },
  {
    name: "Chat Application",
    description:
      "A chat application with signup Login and fully responsive design.",
    tags: [
      {
        name: "nextjs",
        color: "blue-text-gradient",
      },
      {
        name: "Django",
        color: "green-text-gradient",
      },
      {
        name: "djangorestframework",
        color: "pink-text-gradient",
      },
      {
        name: "redux",
        color: "green-text-gradient",
      },
      {
        name: "tailwind",
        color: "blue-text-gradient",
      },
    ],
    image: 'https://res.cloudinary.com/doe77lyoi/image/upload/v1725259884/bakpnh2szxx5s562jvpk.png',
    source_code_link: "https://github.com/Nimeshregmi/chat_App",
  },
  {
    name: "Expense Tracker",
    description:
      "Expense tracker application on Laravel with authentication and fully responsive design.",
    tags: [
      {
        name: " Laravel",
        color: "blue-text-gradient",
      },
      {
        name: " TailwindCSS",
        color: "green-text-gradient",
      },
      {
        name: "AlpineJS",
        color: "pink-text-gradient",
      },
      {
        name: "Jquery",
        color: "green-text-gradient",
      },
      {
        name: "MySQL/Sqlite",
        color: "blue-text-gradient",
      },
      {
        name: " Laravel's breeze",
        color: "pink-text-gradient",
      },
    ],
    image: '/expense-tracker.png',
    source_code_link: "https://github.com/Nimeshregmi/expense-tracker",
  },
];
export const socialMedia = [
  {
    id: 1,
    img: "/github.png",
    href: "https://github.com/Nimeshregmi",
  },
  {
    id: 2,
    img: "/social/twitter.svg",
    href: "https://x.com/nimeshregmi77",
  },
  {
    id: 3,
    img: "/social/linkedin.svg",
    href: "https://www.linkedin.com/in/nimesh-regmi-699047218/",
  },
  {
    id: 3,
    img: "/social/insta.png",
    href: "https://instagram.com/thisisnimeshregmi77",
  },
  {
    id: 3,
    img: "/social/what.png",
    href: "https://wa.me/9814062946",
  },
];
export { services, technologies, experiences, testimonials, projects };


