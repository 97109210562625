"use client";
import { textVariant } from '@/utils/motion'
import { motion } from 'framer-motion'
import React from 'react'

interface Props{
    title:string;
    subTitle?:string;
    id?:string;
}
const TitleWrapper = ({title,id,subTitle}:Props) => {
  return (
    <>
    <motion.div variants={textVariant()} id={`${id}`}>
        <p className={`sectionSubText `}>{subTitle}</p>
        <h2 className={`sectionHeadText`}>{title}.</h2>
      </motion.div>
    </>
  )
}

export default TitleWrapper